import { gql } from "@/utils/gql/tag";
import { f_asset } from "./f_asset";
import { f_button_internal_link } from "./f_button_internal_link";

export const f_button = gql`
  ${f_button_internal_link}
  ${f_asset}
  fragment Button on Button {
    __typename
    id
    title
    buttonStyle
    promoText
    shape
    url
    eventName
    internalLink(locale: $locale) {
      ...Button_internalLink
    }
    icon {
      ...Asset
    }
  }
`;
