"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  width: 202.26px;
  height: 202.26px;
`;

const Silver = css``;

const Gold = css`
  width: 418px;
  height: 418px;
`;

const Platinum = css``;

const Diamond = css`
  height: 502.27px;
  width: 502.27px;
`;

export const SPageNotFoundMeduimCircleWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
