import { gql } from "@/utils/gql/tag";

export const f_button_internal_link = gql`
  fragment Button_internalLink on Button_internalLink {
    __typename
    ... on DocumentationArticle {
      id
      slug
    }
    ... on Page {
      id
      slug
    }
    ... on Documentation {
      id
      type
    }
  }
`;
