import { ApolloQueryResult } from "../types";
import { removeUnf } from "./removeUnf";
import { print } from "graphql";

const apiKey = process.env.CAISY_APIKEY;
const projectId = process.env.CAISY_ID;

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const NETWORK_CODES = ["UND_ERR_CONNECT_TIMEOUT", "EPIPE"];

async function requestWithRetry(
  requestPromise,
  { retries = 4, everyMs = 800 },
  retriesCount = 0
) {
  try {
    return await requestPromise;
  } catch (err) {
    err?.code &&
      console.log(
        ` err.code`,
        err?.code,
        err?.code && NETWORK_CODES.includes(err.code)
      );
    const updatedCount = retriesCount + 1;
    if (updatedCount > retries) {
      throw err;
    }
    await delay(everyMs * retries);
    return await requestWithRetry(
      requestPromise,
      { retries, everyMs },
      updatedCount
    );
  }
}

export const parseBody = ({ query, variables }) => {
  removeUnf(variables);

  const body = JSON.stringify({
    query: typeof query == "string" ? query : print(query),
    variables: variables || null,
  });

  return body;
};

const baseUrl = process.env.NEXT_PUBLIC_CORE_URL || "https://cloud.caisy.io";
const gqlClient = {
  request: async (query, variables) => {
    const body = parseBody({ query, variables });
    const data = await fetch(`${baseUrl}/api/v3/e/${projectId}/graphql`, {
      ...{
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...((process.env.NEXT_PUBLIC_PREVIEW_MODE
            ? { "x-caisy-preview": "true" }
            : {}) as any),
          "x-caisy-apikey": apiKey, // everyone with this repo access can have that key its not critical
        },
        body,
      },
      next: { revalidate: 2 },
    });

    const dataJson = await data.json();
    // if (dataJson && dataJson?.data?.allDocumentationArticle?.edges) {
    //   console.log(` body`, body);
    // }
    return dataJson;
  },
};

export const client = {
  query: async ({
    query,
    variables,
  }: {
    query: any;
    variables?: any;
    fetchPolicy?: any;
  }) => {
    try {
      const data = await requestWithRetry(gqlClient.request(query, variables), {
        retries: 5,
      });
      return data as ApolloQueryResult<any>;
    } catch (e) {
      console.error(`Error(default client) at gql query: `, e);
      return null;
    }
  },
};
