"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  color: var(--text-10);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;
`;

const Silver = css``;

const Gold = css`
  font-size: 18px;
  text-align: left;

  line-height: 24px;
`;
const Platinum = css``;

const Diamond = css`
  font-size: 24px;
  line-height: 36px;
`;

export const SPageNotFoundDescription = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
