import React from "react";

function MeduimCircleDecoration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="504"
      height="504"
      fill="none"
      viewBox="0 0 504 504"
    >
      <circle
        cx="252"
        cy="252"
        r="250.536"
        stroke="url(#paint0_angular_3445_136161)"
        strokeDasharray="1.2 21.63"
        strokeWidth="1.202"
      ></circle>
      <defs>
        <radialGradient
          id="paint0_angular_3445_136161"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(-70.444 437.783 108.272) scale(246.795 124.52)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="0.448" stopColor="#fff" stopOpacity="0.899"></stop>
          <stop offset="0.828" stopColor="#fff" stopOpacity="0.42"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default MeduimCircleDecoration;
