"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  border: 1.20161px solid rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 170px;
  height: 170px;
  position: absolute;
`;

const Silver = css``;

const Gold = css`
  width: 350px;
  height: 350px;
`;

const Platinum = css``;

const Diamond = css`
  width: 420px;
  height: 420px;
`;

export const SPageNotFoundErrorCodeWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
