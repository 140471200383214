import React from "react";
import { GridPadding } from "../../base-components/grid-padding/GridPadding";
import { RichText } from "../../base-components/rich-text/RichText";
import CircularDecoration from "../../svgs/CircularDecoration";
import MeduimCircleDecoration from "../../svgs/MeduimCircleDecoration";
import { IGenScreen404, IGenScreen500 } from "../../utils/types_gen";
import { Button } from "../button/Button";
import { SPageNotFound } from "./styles/SPageNotFound";
import { SPageNotFoundBigSvgWrapper } from "./styles/SPageNotFoundBigSvgWrapper";
import { SPageNotFoundBlockWrapper } from "./styles/SPageNotFoundBlockWrapper";
import { SPageNotFoundButtonWrapper } from "./styles/SPageNotFoundButtonWrapper";
import { SPageNotFoundDescription } from "./styles/SPageNotFoundDescription";
import { SPageNotFoundErrorCode } from "./styles/SPageNotFoundErrorCode";
import { SPageNotFoundErrorCodeWrapper } from "./styles/SPageNotFoundErrorCodeWrapper";
import { SPageNotFoundHeader } from "./styles/SPageNotFoundHeader";
import { SPageNotFoundLeftContainer } from "./styles/SPageNotFoundLeftContainer";
import { SPageNotFoundMeduimCircleWrapper } from "./styles/SPageNotFoundMeduimCircleWrapper";
import { SPageNotFoundSvgWrapper } from "./styles/SPageNotFoundSvgWrapper";

export const PageNotFound: React.FC<IGenScreen404 | IGenScreen500> = ({
  body,
  elements,
  id,
  title,
  __typename,
}) => {
  return (
    <SPageNotFound id={id}>
      <GridPadding>
        <SPageNotFoundBlockWrapper>
          <SPageNotFoundLeftContainer>
            {title && <SPageNotFoundHeader>{title}</SPageNotFoundHeader>}
            {body?.json?.content && (
              <SPageNotFoundDescription>
                <RichText content={body?.json.content} />
              </SPageNotFoundDescription>
            )}

            {!!elements?.length && (
              <SPageNotFoundButtonWrapper>
                {elements.map(
                  (btn) =>
                    btn && (
                      <Button key={btn.id} {...btn}>
                        {btn.title}
                      </Button>
                    )
                )}
              </SPageNotFoundButtonWrapper>
            )}
          </SPageNotFoundLeftContainer>
          <SPageNotFoundSvgWrapper>
            <SPageNotFoundErrorCodeWrapper>
              <SPageNotFoundErrorCode>
                {__typename === "Screen500" ? "500" : "404"}
              </SPageNotFoundErrorCode>
            </SPageNotFoundErrorCodeWrapper>
            <SPageNotFoundBigSvgWrapper>
              <CircularDecoration />
            </SPageNotFoundBigSvgWrapper>
            <SPageNotFoundMeduimCircleWrapper>
              <MeduimCircleDecoration />
            </SPageNotFoundMeduimCircleWrapper>
          </SPageNotFoundSvgWrapper>
        </SPageNotFoundBlockWrapper>
      </GridPadding>
    </SPageNotFound>
  );
};
