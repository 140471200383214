"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  color: var(--text-02);
  font-weight: 800;
  font-size: 50px;
  line-height: 60px;
`;

const Silver = css``;

const Gold = css`
  font-weight: 800;
  font-size: 104px;
  line-height: 126px;
`;

const Platinum = css``;

const Diamond = css`
  font-weight: 800;
  font-size: 124px;
  line-height: 150px;
`;

export const SPageNotFoundErrorCode = styled.h3`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
