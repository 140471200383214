import React from "react";

function CircularDecoration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="596"
      height="596"
      fill="none"
      viewBox="0 0 596 596"
    >
      <circle
        cx="298"
        cy="298"
        r="297.399"
        stroke="url(#paint0_angular_3445_136160)"
        strokeDasharray="1.2 24.03"
        strokeWidth="1.202"
      ></circle>
      <defs>
        <radialGradient
          id="paint0_angular_3445_136160"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(139.64584 -175.0585 315.43112 251.62243 158.354 473.058)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="0.604" stopColor="#fff" stopOpacity="0.684"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
}

export default CircularDecoration;
