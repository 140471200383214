"use client";
import styled, { css } from "styled-components";
import {
  MIN_SILVER,
  MIN_GOLD,
  MIN_PLATINUM,
  MIN_DIAMOND,
} from "../../../constants/styles/mediaquerys";

const Bronze = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 240px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Silver = css``;

const Gold = css`
  width: 496px;
  height: 496px;
`;

const Platinum = css``;

const Diamond = css`
  width: 596px;
  height: 596px;
`;

export const SPageNotFoundSvgWrapper = styled.div`
  ${Bronze}
  ${MIN_SILVER`${Silver}`};
  ${MIN_GOLD`${Gold}`};
  ${MIN_PLATINUM`${Platinum}`};
  ${MIN_DIAMOND`${Diamond}`};
`;
