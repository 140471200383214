import { gql as x } from "graphql-tag";
export const gql = x;
// export function gql(literals, ...interpolations) {
//   let result = "";

//   for (let i = 0; i < literals.length; i++) {
//     result += literals[i];
//     if (i < interpolations.length) {
//       result += interpolations[i];
//     }
//   }

//   return result;
// }
