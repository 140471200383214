"use client";
import { client } from "../../src/utils/gql/client";
import { q_InternalError } from "../../src/utils/gql/queries/q_InternalError";
import { IGenQuery } from "../../src/utils/types_gen";
import { PageNotFound } from "../../src/components/page-not-found/PageNotFound";
import { ApolloQueryResult } from "../../src/utils/types";

const getScreen500 = async ({ locale = "en" }) => {
  const [resScreen500]: [ApolloQueryResult<Pick<IGenQuery, "Screen500">>] =
    await Promise.all([
      client.query({
        query: q_InternalError,
        variables: { locale },
        fetchPolicy: "no-cache",
      }),
    ]);
  return {
    screen500: resScreen500?.data?.Screen500 || null,
  };
};

export default async function GlobalError({
  error,
  reset,
}: {
  error: Error;
  reset: () => void;
}) {
  const pageProps = await getScreen500({ locale: "en" });
  console.log(` error`, error);
  console.log(` reset`, reset);
  return (
    <>{pageProps?.screen500 && <PageNotFound {...pageProps?.screen500} />}</>
  );
}
