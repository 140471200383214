import { gql } from "@/utils/gql/tag";

export const f_asset = gql`
  fragment Asset on Asset {
    __typename
    id
    title
    src
    description
    keywords
    originalName
    originType
    copyright
    dominantColor
    width
    height
    blurHash
  }
`;
