import { gql } from "@/utils/gql/tag";
import { f_button } from "./fragments/f_button";

export const q_InternalError = gql`
  ${f_button}

  query q_InternalError($locale: String!) {
    Screen500 {
      __typename
      id
      title
      body {
        json
      }
      elements(locale: $locale) {
        ...Button
      }
    }
  }
`;
